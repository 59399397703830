@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --max-width: 1100px;
  --border-radius: 12px;
  --font-mono: ui-monospace, Menlo, Monaco, "Cascadia Mono", "Segoe UI Mono",
    "Roboto Mono", "Oxygen Mono", "Ubuntu Monospace", "Source Code Pro",
    "Fira Mono", "Droid Sans Mono", "Courier New", monospace;

  --foreground-rgb: 255, 255, 255;
  --background-start-rgb: 255, 255, 255;
  --background-end-rgb: 255, 255, 255;

  --primary-glow: conic-gradient(
    from 180deg at 50% 50%,
    #16abff33 0deg,
    #0885ff33 55deg,
    #54d6ff33 120deg,
    #0071ff33 160deg,
    transparent 360deg
  );
  --secondary-glow: radial-gradient(
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 0)
  );

  --tile-start-rgb: 239, 245, 249;
  --tile-end-rgb: 228, 232, 233;
  --tile-border: conic-gradient(
    #00000080,
    #00000040,
    #00000030,
    #00000020,
    #00000010,
    #00000010,
    #00000080
  );

  --callout-rgb: 238, 240, 241;
  --callout-border-rgb: 172, 175, 176;
  --card-rgb: 180, 185, 188;
  --card-border-rgb: 131, 134, 135;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 0, 0, 0;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;

    --primary-glow: radial-gradient(rgba(1, 65, 255, 0.4), rgba(1, 65, 255, 0));
    --secondary-glow: linear-gradient(
      to bottom right,
      rgba(1, 65, 255, 0),
      rgba(1, 65, 255, 0),
      rgba(1, 65, 255, 0.3)
    );

    --tile-start-rgb: 2, 13, 46;
    --tile-end-rgb: 2, 5, 19;
    --tile-border: conic-gradient(
      #ffffff80,
      #ffffff40,
      #ffffff30,
      #ffffff20,
      #ffffff10,
      #ffffff10,
      #ffffff80
    );

    --callout-rgb: 20, 20, 20;
    --callout-border-rgb: 108, 108, 108;
    --card-rgb: 100, 100, 100;
    --card-border-rgb: 200, 200, 200;
  }
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  max-width: 100vw;
  overflow-x: hidden;
}

body {
  color: rgb(var(--foreground-rgb));
  background: #ffffff;
  /* background: linear-gradient(
      to bottom,
      transparent,
      rgb(var(--background-end-rgb))
    )
    rgb(var(--background-start-rgb)); */
}

a {
  color: inherit;
  text-decoration: none;
}
/* 
@media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
  }
} */


html, body, label {
  font-family: 'Roboto', sans-serif;
}

img:not([src]) {
   visibility: hidden;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}
input[type=number] {
    -moz-appearance:textfield; /* Firefox */
}

.markdown {
    font-size: 1rem;
    line-height: 1.75rem;
    color: #374151;
}

.markdown h1 {
    margin-bottom: 1.5rem;
    font-size: 2rem;
    font-weight: 700;
    line-height: 2rem;
    letter-spacing: -0.025em;
    color: #161e2e;
}

.markdown h2 {
    margin-top: 3rem;
    margin-bottom: 1.5rem;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 2rem;
    letter-spacing: -0.025em;
    color: #161e2e;
}

.markdown h2:first-child {
    margin-top: 0;
}

.markdown h3 {
    margin-bottom: 1.25rem;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 2rem;
    letter-spacing: -0.025em;
    color: #161e2e;
}

.markdown ol li:before {
    content: counter(list-counter) '.';
    position: absolute;
    left: 0;
    font-weight: 600;
    color: #6b7280;
}

.markdown ul li {
    position: relative;
    padding-left: 2rem;
}

.markdown ul li:before {
    content: '';
    position: absolute;
    top: 0.8125em;
    left: 0;
    background-color: #9fa6b2;
    height: 0.125em;
    width: 0.75em;
}

.markdown blockquote {
    font-style: italic;
    padding: 1rem;
    font-size: 1.25rem;
    line-height: 1.75rem;
    border-left-width: 4px;
    margin: 0 0 30px;
}

.markdown blockquote p {
    margin-bottom: 0;
}

.markdown p {
    margin-bottom: 1rem;
}

pre {
    color: #e5e7eb;
    font-size: 0.875rem;
    font-family: Menlo, Monaco, Consolas, Liberation Mono, Courier New,
        monospace;
    line-height: 1.5rem;
    border-radius: 0.375rem;
    background-color: #252f3f;
    padding: 0.75rem 1rem;
    overflow-x: auto;
    margin-bottom: 30px;
    @apply rounded-md;
    @apply bg-gray-800;
    @apply py-3;
    @apply px-4;
    @apply overflow-x-auto;
}

p code,
li code {
    font-size: 0.875rem;
    line-height: 1.75rem;
    font-family: Menlo, Monaco, Consolas, Liberation Mono, Courier New,
        monospace;
    color: #374151;
    background-color: #f9fafb;
    border-color: #e5e7eb;
    border-width: 1px;
    border-radius: 0.375rem;
    padding: 0.25rem 0.375rem;
}

hr {
    height: 1px;
    background-color: #d2d6dc;
}
